<template>
  <div>
    <b-card class="cs-relative cs-print">
      <div class="d-print-none">
        <div class="d-print-none d-flex justify-content-end mr-4 mb-3">
          <b-button @click="handlePrint" variant="primary">{{
            $t("lbl_print_bill")
          }}</b-button>
        </div>
        <span @click="handleClose" class="cs-close">&times;</span>
      </div>
      <div class="container cs-container">
        <div ref="documentPrint" id="documentPrint" class="h-full">
          <div class="row">
            <div class="col-3"></div>
            <div class="col-6 text-center font-weight-bold">
              <h3 class="text-uppercase">{{ $t("lbl_national_brand") }}</h3>
              <h5>{{ $t("lbl_sub_national_brand") }}</h5>
            </div>
            <div class="col-3 d-flex justify-content-end cs-text-bold">
              <span>Số:</span>
              <span class="ml-1">{{ receivingData.stock_in_code }}</span>
            </div>
          </div>
          <div class="text-center mt-2">
            <h2 class="text-uppercase">{{ $t("lbl_receiving") }}</h2>
            <h4 v-if="receivingData.partner_inventory" class="m-0">
              {{ receivingData.partner_inventory.name }}
            </h4>
            <span>
              {{ $t("lbl_receive_date") }}:
              <i>{{ formatDateTime(receivingData.date_add) }}</i>
            </span>
          </div>
          <div class="row mt-2">
            <div class="col-6">
              <span>{{ $t("lbl_receiving_by") }}:</span>
              <span class="ml-1 cs-text-bold">{{
                getCreatedInfor(receivingData)
              }}</span>
            </div>
            <div class="col-3">
              <span>{{ $t("lbl_invoice_number_short") }}:</span>
              <span class="ml-1 cs-text-bold">{{
                receivingData.invoice_code
              }}</span>
            </div>
            <div class="col-3">
              <span>{{ $t("lbl_invoice_date_short") }}:</span>
              <span class="ml-1 cs-text-bold">{{
                formatDate(receivingData.day_contract)
              }}</span>
            </div>
          </div>
          <div class>
            <span>{{ $t("lbl_supplier_short") }}:</span>
            <span class="ml-1 cs-text-bold">{{
              getProviderLabel(receivingData)
            }}</span>
          </div>
          <b-overlay
            :show="false"
            rounded="sm"
            spinner-variant="primary"
            spinner-type="grow"
            spinner-small
          >
            <b-table
              small
              striped
              bordered
              outlined
              class="cs-btable mt-2"
              responsive
              :items="products"
              :fields="tableColumns"
            >
              <template #cell(index)="data">
                <span class="text-capitalize">{{ data.index + 1 }}</span>
              </template>
              <template #cell(name)="data">
                <span class="text-capitalize">{{
                  data.item.partner_product.name
                }}</span>
              </template>
              <template #cell(unit_id)="data">
                <span class="text-capitalize">{{ getUnit(data.item) }}</span>
              </template>
              <template #cell(lot_number)="data">
                <span class="text-capitalize">{{
                  getLotNumber(data.item)
                }}</span>
              </template>
              <template #cell(expired_date)="data">
                <span class="text-capitalize">{{
                  formatExpiredDate(data.item)
                }}</span>
              </template>
              <template #cell(qty)="data">
                <span class="text-capitalize">{{ data.item.variant_qty }}</span>
              </template>
              <!-- <template #cell(price)="data">
                <span class="text-capitalize">{{appUtils.numberFormat(data.item.realPrice) }}</span>
              </template>
              <template #cell(total_price)="data">
                <span class="text-capitalize">{{appUtils.numberFormat(data.item.total_price) }}</span>
              </template>-->
              <!-- <template #bottom-row>
                <b-th
                  colspan="7"
                  class="text-right"
                  style="font-weight:500"
                >{{ $t('lbl_total_price') }}</b-th>
                <b-th
                  class="text-right"
                  style="font-weight:500"
                >{{ appUtils.numberFormat(receivingData.total_price) }}</b-th>
              </template>-->
            </b-table>
          </b-overlay>
          <!-- <div class="cs-text-bold mt-3">
            <div>
              <span>- {{ $t('lbl_total_price') }}:</span>
              <span
                v-if="receivingData.total_price"
                class="ml-1"
              >{{ formatPrice(receivingData.total_price) }}</span>
            </div>
            <div class="d-flex align-items-center">
              <span>- {{ $t('lbl_total_price_text') }}:</span>
              <span v-if="receivingData.total_price" class="ml-1 cs-capitalize">
                <p class="m-0">{{ `${appUtils.numberToWords((receivingData.total_price))}` }}</p>
              </span>
            </div>
          </div>-->
          <div class="row mt-3 mb-4">
            <div class="col-4 text-center">
              <div>
                <div class="cs-text-bold">
                  {{ $t("lbl_created_receiving") }}
                </div>
                <div>
                  <i>{{ $t("lbl_sign_name") }}</i>
                </div>
                <div
                  v-if="receivingData.created_by_infor"
                  style="margin-top: 75px"
                  class="text-uppercase"
                >
                  {{ receivingData.created_by_infor.name }}
                </div>
              </div>
            </div>
            <div class="col-4 text-center">
              <div>
                <div class="cs-text-bold">
                  {{ $t("lbl_receiving_by_shot") }}
                </div>
                <div>
                  <i>{{ $t("lbl_sign_name") }}</i>
                </div>
                <div
                  v-if="receivingData.receiver_name"
                  style="margin-top: 75px"
                  class="text-uppercase"
                >
                  {{ receivingData.receiver_name }}
                </div>
              </div>
            </div>
            <!-- <div class="col-3 text-center">
              <div>
                <div class="cs-text-bold">{{ $t('lbl_accountant') }}</div>
                <div class="text-center">
                  <i>{{ $t('lbl_sign_name') }}</i>
                </div>
              </div>
            </div>-->
            <div class="col-4 text-center">
              <div>
                <div class="cs-text-bold">{{ $t("lbl_expertise") }}</div>
                <div class="text-center">
                  <i>{{ $t("lbl_sign_name") }}</i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>
  
<script>
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
import appUtils from "@/utils/appUtils";
import i18n from "../../../libs/i18n";

const tableColumns = [
  { key: "index", label: "STT", sortable: false },
  {
    key: "name",
    label: i18n.t("lbl_product_name_short"),
    sortable: false,
  },
  {
    key: "unit_id",
    label: i18n.t("lbl_units"),
    sortable: false,
  },
  {
    key: "lot_number",
    label: i18n.t("lbl_lot_number"),
    sortable: false,
  },
  {
    key: "expired_date",
    label: i18n.t("lbl_expired_date"),
    sortable: false,
  },
  {
    key: "qty",
    label: i18n.t("lbl_amount"),
    sortable: false,
    class: "text-center",
  },
  // {
  //   key: "price",
  //   label: i18n.t("lbl_price_vat"),
  //   sortable: false,
  //   class: "text-right"
  // },
  // {
  //   key: "total_price",
  //   label: i18n.t("lbl_end_price"),
  //   sortable: false,
  //   class: "text-right"
  // }
];

export default {
  name: "ReceivingDetail",
  data() {
    return {
      products: [],
      receivingData: {},
      appUtils,
      tableColumns,
    };
  },
  watch: {
    $route() {
      this.handleViewDetail();
    },
  },
  created() {
    this.handleViewDetail();
  },
  methods: {
    getLotNumber(data) {
      return data?.product_lot?.name || "";
    },
    getCreatedInfor(data) {
      return data?.created_by_infor?.name || "";
    },
    formatPrice(price) {
      return appUtils.numberFormat(price, "VNĐ");
    },
    handlePrint() {
      window.print();
    },
    handleClose() {
      this.$router.replace({ name: "import" });
    },
    async handleViewDetail() {
      try {
        const response = await this.$store.dispatch(
          "stockIn/fetchStockInById",
          { id: this.$route.params.id }
        );
        console.log(response);

        this.products = (response.data?.stock_in_detail || [])?.map((item) => {
          // const realPrice =
          //   (item?.input_price || 0) * (1 + item?.product_info?.vat_in / 100);
          // const roundPrice = Math.round((realPrice * 100) / 100);
          return {
            ...item,
            // realPrice: roundPrice,
            // total_price: (roundPrice || 0) * (item?.qty || 0)
          };
        });

        // const totalPrice = this.medicines?.reduce(
        //   (total, item) => total + (item?.total_price || 0),
        //   0
        // );
        this.receivingData =
          {
            ...response.data,
            // total_price: totalPrice
          } || {};
      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Có lỗi xảy ra khi lấy dữ liệu",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    formatPrice(price) {
      return appUtils.numberFormat(price, "VNĐ");
    },
    getTotalPriceItem(item) {
      return appUtils.numberFormat((item?.input_price || 0) * (item?.qty || 0));
    },
    getUnit(data) {
      return data?.product_variant?.product_unit?.name || "";
    },
    getCountryLabel(data) {
      return data?.product_info?.country_info?.name || "";
    },
    formatExpiredDate(data) {
      return appUtils.formatUnixTimeToDate(data.product_lot.expiration_date);
    },
    formatDate(timestamp) {
      return window.moment(timestamp).format("DD/MM/YYYY");
    },
    formatDateTime(timestamp) {
      return window.moment(timestamp).format("HH:mm DD/MM/YYYY");
    },
    getProviderLabel(data) {
      return data?.product_provider?.name;
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.cs-container {
  position: relative;
  min-height: calc(100vh - 31px);
  max-width: 1000px;
  background-color: #fff !important;
}

.cs-container * {
  color: #000;
  font-family: "Times New Roman", Times, serif;
  font-size: 18px;

  h2 {
    font-weight: 600;
    font-size: 26px;
  }
  h3 {
    font-size: 20px;
    font-weight: 600;
  }
  h4 {
    font-size: 18px;
    font-weight: 600;
  }
  h5 {
    font-size: 16px;
    font-weight: 600;
  }

  .cs-text-bold {
    font-weight: 600;
  }

  .cs-table {
    // width: 1000px;
    margin-top: 16px;
    .w-6 {
      width: 6%;
      padding-left: 8px;
      padding-right: 8px;
    }
    .w-15 {
      width: 15%;
      padding-left: 8px;
      padding-right: 8px;
    }
    .w-8 {
      width: 8%;
      padding-left: 8px;
      padding-right: 8px;
    }
    .w-10 {
      width: 10%;
      padding-left: 8px;
      padding-right: 8px;
    }
    .w-12 {
      width: 12%;
      padding-left: 8px;
      padding-right: 8px;
    }

    .w-13 {
      width: 13%;
      padding-left: 8px;
      padding-right: 8px;
    }

    .w-16 {
      width: 16%;
      padding-left: 8px;
      padding-right: 8px;
    }

    .w-18 {
      width: 18%;
      padding-left: 8px;
      padding-right: 8px;
    }
    .w-20 {
      width: 20%;
      padding-left: 8px;
      padding-right: 8px;
    }
    .w-90 {
      width: 85%;
      padding: 4px;
      padding-left: 8px;
      padding-right: 8px;
    }

    .table-item {
      border-top: unset;
    }

    .body-item {
      padding-top: 8px;
      padding-bottom: 8px;
      // border-right: 1px solid #787878;

      box-sizing: border-box;
    }
  }
  .cs-table * {
    font-size: 16px;
  }

  .border-t {
    border-top: 1px solid #787878;
  }
  .border-b {
    border-bottom: 1px solid #787878;
  }
  .border-l {
    border-left: 1px solid #787878;
  }
  .border-r {
    border-right: 1px solid #787878;
  }
  .cs-border {
    border: 1px solid #787878;
  }
  .cs-capitalize {
    text-transform: lowercase;

    p::first-letter {
      text-transform: capitalize;
    }
  }
}
.cs-relative {
  position: relative;
}

.cs-close {
  display: block;
  width: 100%;
  position: absolute;
  top: 10px;
  right: 16px;
  height: 30px;
  width: 30px;
  font-size: 40px;
  z-index: 100;
  cursor: pointer;
}

::v-deep .cs-btable * {
  color: #000;
  font-family: "Times New Roman", Times, serif;

  thead * {
    font-size: 16px;
    text-transform: none !important;
  }

  tbody {
    font-size: 16px;
  }
}

.cs-print {
  margin: 0 !important;
}

@media print {
  body {
    -webkit-print-color-adjust: exact;
  }

  .cs-print {
    display: block !important;
    overflow: hidden;
    margin-top: 30px;

    .card-body {
      padding: 0 !important;
    }
  }

  .cs-print * {
    font-size: 20px;
  }

  .cs-container {
    margin-top: 30px;
  }
}
</style>